import 'app/jquery-jvectormap';
import 'app/jquery-jvectormap-world-mill';

import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';
import { fontBase, datasetBase, smallLineChart } from '../helpers/charts';
import { prettyNumber, prettyNumberAbbreviated } from '../helpers/numbers';

// Connects to data-controller="analytics"
export default class extends Controller {
	static targets = ['statsChart', 'sessionsSmallChart', 'viewsSmallChart', 'worldMap'];

	connect() {
		this.renderSmallCharts();
		this.renderStatsChart();
	}

	smallCharts() {
		return [
			this.hasSessionsSmallChartTarget && this.sessionsSmallChartTarget,
			this.hasViewsSmallChartTarget && this.viewsSmallChartTarget
		].filter(x => x);
	}

	renderSmallCharts() {
		const smallCharts = this.smallCharts();
		smallCharts.forEach(smallChart => {
			smallLineChart(
				smallChart,
				smallChart.dataset.label, // legend label
				JSON.parse(smallChart.dataset.labels), // labels
				JSON.parse(smallChart.dataset.values), // data
				20000 // step size
			);
		});
	}

	renderStatsChart() {
		if (this.hasStatsChartTarget) {
			const ctx = this.statsChartTarget.getContext('2d');
			const isSingleDay = JSON.parse(this.statsChartTarget.dataset.isSingleDay);
			// define the custom plugin for the vertical line on hover
			const verticalLinePlugin = {
				id: 'verticalLine',
				afterEvent(chart, args) {
					const event = args.event;
					// check if mousemove event
					if (event.type === 'mousemove') {
						let nearestPoint = null;
						let minimumDistance = Number.MAX_VALUE;
						let nearestDatasetIndex = -1;
						let nearestIndex = -1;
						// iterate over each dataset
						chart.data.datasets.forEach((dataset, datasetIndex) => {
							const meta = chart.getDatasetMeta(datasetIndex);
							// ensure dataset is visible
							if (meta.visible) {
								// iterate over each element in the dataset
								meta.data.forEach((point, index) => {
									const distance = Math.abs(event.x - point.x);
									// update nearest point if this point is closer in x-direction
									if (distance < minimumDistance) {
										nearestPoint = point;
										minimumDistance = distance;
										nearestDatasetIndex = datasetIndex;
										nearestIndex = index;
									}
								});
							}
						});
						// set the vertical line to the nearest point's x position
						if (nearestPoint !== null) {
							chart._verticalLine = {
								x: nearestPoint.x,
								y: chart.chartArea.bottom
							};
							// trigger hover state for the nearest point
							chart.setActiveElements([
								{
									datasetIndex: nearestDatasetIndex,
									index: nearestIndex
								}
							]);
						} else {
							chart._verticalLine = null;
							chart.setActiveElements([]); // Clear any active elements
						}
					} else {
						chart._verticalLine = null;
						chart.setActiveElements([]); // Clear any active elements on other events
					}
					chart.draw();
				},
				afterDraw(chart) {
					if (chart._verticalLine) {
						const ctx = chart.ctx;
						ctx.save();
						ctx.beginPath();
						ctx.strokeStyle = '#272727';
						ctx.moveTo(chart._verticalLine.x, chart.chartArea.top);
						ctx.lineTo(chart._verticalLine.x, chart._verticalLine.y);
						ctx.stroke();
						ctx.restore();
					}
				}
			};
			this.chart = new Chart(ctx, {
				type: 'line',
				data: {
					labels: JSON.parse(ctx.canvas.dataset.labels),
					datasets: this.statsDataset()
				},
				options: {
					animation: false,
					maintainAspectRatio: false,
					plugins: {
						legend: { display: false },
						tooltip: {
							intersect: false,
							mode: 'index',
							padding: '10',
							titleColor: '#babcc3',
							titleFont: fontBase(),
							bodyColor: '#fff',
							bodyFont: fontBase(),
							backgroundColor: '#272727',
							caretSize: 5,
							caretPadding: 5,
							cornerRadius: 5,
							displayColors: false
						}
					},
					scales: {
						x: {
							grid: {
								display: false
							},
							border: {
								display: false
							},
							ticks: {
								padding: 20,
								color: '#babcc3',
								font: fontBase({ weight: 600 }),
								autoSkip: true,
								maxTicksLimit: 7,
								maxRotation: 0,
								minRotation: 0,
								callback: function (value, index, values) {
									// TODO: the crudest of crudes...
									// Perhaps someone can help me install moment.js
									const chart = this.chart;
									const label = chart.data.labels[index];
									if (label && typeof label === 'string') {
										return isSingleDay ? label.split(' at ')[1] : label.split(',')[0];
									} else {
										return value;
									}
								}
							}
						},
						y: {
							beginAtZero: true,
							grid: {
								drawTicks: false
							},
							border: {
								display: false,
								dash: [5, 5]
							},
							ticks: {
								beginAtZero: true,
								maxTicksLimit: 6,
								padding: 20,
								color: '#babcc3',
								font: fontBase({ weight: 600 }),
								stepSize: 1000,
								callback: prettyNumberAbbreviated
							}
						}
					}
				},
				plugins: [verticalLinePlugin]
			});
			// set only the first dataset visible and first button .active on load
			this.chart.data.datasets.forEach((dataset, index) => {
				this.chart.getDatasetMeta(index).hidden = index !== 0;
			});
			this.chart.update();
			this.setActiveButton(0);
		}
	}

	statsDataset() {
		const ctx = this.statsChartTarget.getContext('2d');
		return [
			datasetBase(ctx, {
				label: 'Visits',
				data: JSON.parse(ctx.canvas.dataset.totalsessions)
			}),
			datasetBase(ctx, {
				label: 'Page Views',
				data: JSON.parse(ctx.canvas.dataset.totalviews)
			}),
			datasetBase(ctx, {
				label: 'Time on Site',
				data: JSON.parse(ctx.canvas.dataset.totaltime)
			}),
			datasetBase(ctx, {
				label: 'Bounce Rate',

				data: JSON.parse(ctx.canvas.dataset.totalbounce)
			})
		];
	}

	toggleHeader(event) {
		event.preventDefault();
		const header = event.params.header;
		const index = this.getDatasetIndex(header);

		if (this.chart) {
			// hide all datasets and show the selected one
			this.chart.data.datasets.forEach((dataset, i) => {
				this.chart.getDatasetMeta(i).hidden = i !== index;
			});
			this.chart.update();
		}

		// update button states
		this.setActiveButton(index);
	}

	getDatasetIndex(header) {
		const indexMap = { sessions: 0, pageViews: 1, timeOnSite: 2, bounceRate: 3 };
		return indexMap[header];
	}

	setActiveButton(activeIndex) {
		const buttons = document.querySelectorAll('#chart-toggle button');
		buttons.forEach((button, index) => {
			if (index === activeIndex) {
				button.classList.add('active');
			} else {
				button.classList.remove('active');
			}
		});
	}

	togglePieChart(e) {
		const widget = e.target.closest('.widget');
		const chartType = widget.dataset.chartType;

		if (chartType === 'pie') {
			widget.dataset.chartType = 'list';
		} else {
			widget.dataset.chartType = 'pie';
			const canvas = widget.querySelector('canvas');

			// Very annoying but to get Chart.js to render the pie chart
			// properly we "re-render" the canvas by replacing it with...itself
			// that way the "pieCharts" controller adds the chart to the page
			// using the connect() controller function
			canvas.replaceWith(canvas);
		}
	}

	toggleWorldMap(e) {
		const widget = e.target.closest('.widget');
		const chartType = widget.dataset.chartType;

		if (chartType === 'map') {
			widget.dataset.chartType = 'list';
		} else {
			widget.dataset.chartType = 'map';

			this.initializeWorldMap();
		}
	}

	toggleFullScreenMap(e) {
		const worldMap = e.target.closest('.worldMap');
		const mapSize = worldMap.dataset.mapSize;

		//FULL
		if (mapSize === 'small' || !mapSize) {
			worldMap.setAttribute('data-map-size', 'full');
			//SMALL
		} else if (mapSize === 'full') {
			worldMap.setAttribute('data-map-size', 'small');
		}

		const mapElement = $(worldMap);
		const mapObject = mapElement.vectorMap('get', 'mapObject');
		mapObject.updateSize();
	}

	initializeWorldMap() {
		const worldMap = this.worldMapTarget;

		if (!worldMap.dataset.mapInitialized) {
			const analyticsData = JSON.parse(worldMap.dataset.values);

			$(worldMap).vectorMap({
				map: 'world_mill',
				backgroundColor: 'transparent',
				zoomOnScroll: false,
				regionStyle: {
					initial: {
						fill: '#f7f7f7', // Default color for all countries
						'fill-opacity': 1,
						stroke: 'none',
						'stroke-width': 1,
						'stroke-opacity': 1
					},
					hover: {
						fill: '#000000'
						//"fill-opacity": 0.8
					}
				},
				series: {
					regions: [
						{
							values: analyticsData,
							scale: ['#e8f4fd', '#4aa7f8'], // Define color range
							normalizeFunction: 'polynomial'
						}
					]
				},
				onRegionTipShow: function (e, el, code) {
					if (analyticsData[code]) {
						//el.html('<span class="map-tip-country">'+el.html()+'</span>'+analyticsData[code]+' Page Views'); //LABEL TEXT

						// Construct the URL for the flag image
						// Removing for now, we would need to move over the SVG flags if we use this...
						//const flagIconHtml =
						//	`<img class="flag-icon" src="assets/img/flags/` + code.toLowerCase() + `.svg">`;

						// Modify the tooltip content
						el.html(
							`<span class="map-tip-country">${el.html()}</span>Page Views: ${prettyNumber(
								analyticsData[code]
							)}`
						);
					}
				}
			});

			// Set a flag indicating the map has been initialized
			worldMap.dataset.mapInitialized = true;
		}
	}
}
