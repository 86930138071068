import { datasetBase } from '../helpers/charts';

import AnalyticsController from './analytics_controller';

// Connects to data-controller="event"
export default class extends AnalyticsController {
	static targets = ['uniqueActionsSmallChart', 'viewsSmallChart'];

	smallCharts() {
		return [this.uniqueActionsSmallChartTarget, this.viewsSmallChartTarget];
	}

	getDatasetIndex(key) {
		return { uniqueActions: 0, actions: 1 }[key];
	}

	statsDataset() {
		const ctx = this.statsChartTarget.getContext('2d');
		return [
			datasetBase(ctx, {
				label: 'Unique Actions',
				data: JSON.parse(ctx.canvas.dataset.totalUniqueActions)
			}),
			datasetBase(ctx, {
				label: 'Actions',
				data: JSON.parse(ctx.canvas.dataset.totalActions)
			})
		];
	}
}
