import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';

export default class extends Controller {
	connect() {
		this.initializeChart();
	}

	initializeChart() {
		const canvas = this.element.querySelector('#chart-dmarc canvas');
		const ctx = canvas.getContext('2d');

		const gradient = ctx.createLinearGradient(0, 0, 0, 280);
		gradient.addColorStop(0, '#8dc5f6'); // blue
		gradient.addColorStop(1, '#c8daff'); // light blue

		const dmarcChart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: ['January', 'February', 'March', 'April', 'May', 'June'],
				datasets: [
					{
						label: 'Pass',
						data: [1745, 254, 1137, 634, 198, 1242],
						backgroundColor: gradient
					},
					{
						label: 'Fail',
						data: [56, 19, 3, 9, 2, 7],
						backgroundColor: '#f27c98',
						barPercentage: 0.85,
						inflateAmount: 3 // TODO: this is super janky
					}
				]
			},
			options: {
				animation: false,
				maintainAspectRatio: false,
				scales: {
					x: {
						stacked: true,
						grid: {
							display: false
						},
						border: {
							display: false
						},
						ticks: {
							display: false, // TODO: should probably show these
							padding: 20,
							color: '#babcc3',
							font: {
								family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
								size: 13,
								weight: 600
							}
						}
					},
					y: {
						beginAtZero: true,
						stacked: true,
						grid: {
							drawTicks: false
						},
						border: {
							display: false,
							dash: [5, 5]
						},
						ticks: {
							padding: 20,
							color: '#babcc3',
							font: {
								family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
								size: 13,
								weight: 600
							},
							stepSize: 500,
							callback: function (value) {
								// tick callback
								if (value >= 1000 && value < 1000000) {
									return value / 1000 + 'K'; // Convert to K for thousands
								} else if (value >= 1000000) {
									return value / 1000000 + 'M'; // Convert to M for millions
								} else {
									return value; // Return the original value for smaller numbers
								}
							}
						}
					}
				},
				plugins: {
					//legend: {
					//	display: false
					//},
					tooltip: {
						intersect: false,
						mode: 'index',
						padding: '10',
						titleColor: '#babcc3',
						titleFont: {
							family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
							size: 13,
							weight: 500
						},
						bodyColor: '#fff',
						bodyFont: {
							family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
							size: 13,
							weight: 500
						},
						backgroundColor: '#272727',
						caretSize: 5,
						caretPadding: 0,
						cornerRadius: 5,
						displayColors: false
					}
				}
			}
		});
	}
}
