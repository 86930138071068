import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	connect() {
		// this refocuses a modal after closing a dialog within the modal
		// which allows things like esc. to close the modal to work without
		// doing a manual refocus
		Array.from(document.querySelectorAll('.modal')).forEach(modal =>
			modal.addEventListener('click', e => {
				if (e.srcElement.classList.contains('dialog-close')) {
					modal.focus();
				}
			})
		);
	}

	open(e) {
		const modalOpen = e.target.closest('.modal-open');
		const modalTarget = modalOpen.dataset.modalTarget;
		const modal = $('[data-modal-content=' + modalTarget + ']');

		// show the modal
		modal.fadeIn(300);
		modal.focus();

		// if body has scrollbar, add padding to equal the width of the scrollbar
		var scrollbarWidth = window.innerWidth - document.body.clientWidth;
		// add body padding if a vertical scrollbar exists
		if (scrollbarWidth > 0) {
			$('html').css('padding-right', scrollbarWidth);
		}
		// hide body overflow
		$('body').addClass('no-scroll');
	}

	close(e) {
		const source = e.srcElement;
		if (source.classList.contains('modal') || source.classList.contains('modal-close')) {
			const modal = e.target.closest('.modal');
			$(modal).fadeOut(150, function () {
				// show body overflow again and remove padding
				$('html').css('padding-right', '0');
				$('body').removeClass('no-scroll');
			});
		} else {
			// this makes esc work in the case where we close a dialog within the modal
			// using the esc button
			const modal = e.target.closest('.modal');
			modal.focus();
		}
	}
}
