export function prettyNumber(number) {
	let [integerPart, decimalPart] = number.toString().split('.');
	integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
}

export function prettyNumberRounded(number, decimalPlaces = 0) {
	let roundedNumber = Number(number.toFixed(decimalPlaces));
	return prettyNumber(roundedNumber);
}

export function prettyNumberAbbreviated(value) {
	// tick callback
	if (value >= 1000 && value < 1000000) {
		return value / 1000 + 'K'; // convert to K for thousands
	} else if (value >= 1000000) {
		return value / 1000000 + 'M'; // convert to M for millions
	} else {
		return value; // return the original value for smaller numbers
	}
}
export function calculatePercent(numerator, denominator) {
	if (!numerator || !denominator) {
		return 0;
	}

	return numerator / denominator;
}

export function prettyPercent(decimalPercent, precision = 0) {
	return new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: precision }).format(
		decimalPercent
	);
}
