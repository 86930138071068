import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="filters"
export default class extends Controller {
	static targets = [
		'valueField',
		'filterForm',
		'filtersMenu',
		'metadataKey',
		'metadataValue',
		'metadataValuesForm',
		'metadataValueSection',
		'metadataSubmitButton'
	];

	connect() {
		scrollMeTheWay('.is-filter-label', 0, 1);
	}

	openFilterSection(event) {
		const optionsTarget = event.target.nextElementSibling.querySelector('form');
		const parentLi = event.target.closest('li');
		const searchField = event.target.closest('li').querySelector('.page-filter-search-input');

		if (optionsTarget && !parentLi.classList.contains('active')) {
			optionsTarget.requestSubmit();
		}

		parentLi.classList.toggle('active');

		if (searchField) {
			searchField.focus();
		}

		// Remove 'active' class from all other li elements that are parents of .filter-type
		document.querySelectorAll('.filter-type').forEach(function (otherElement) {
			const otherParentLi = otherElement.closest('li');
			if (otherParentLi !== parentLi) {
				otherParentLi.classList.remove('active');
			}
		});

		// Check if any parent li of a .filter-type element is active
		const anyActive =
			document.querySelector('.filter-type').closest('ul').querySelector('li.active') !== null;
		const parentUl = event.target.closest('ul');

		if (anyActive) {
			parentUl.classList.add('active');
		} else {
			parentUl.classList.remove('active');
		}
	}

	search(event) {
		const filterOptionsForm = event.target.closest('form');
		const closestLi = event.target.closest('li');

		if (event.target.value.trim()) {
			closestLi.classList.add('has-value');
		} else {
			closestLi.classList.remove('has-value');
		}

		if (filterOptionsForm) {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				filterOptionsForm.requestSubmit();
			}, 200);
		}
	}

	clearSearch(event) {
		const filterOptionsForm = event.target.closest('form');
		const closestLi = event.target.closest('li');
		const searchField = event.target.closest('li').querySelector('.page-filter-search-input');

		searchField.value = '';
		closestLi.classList.remove('has-value');

		if (filterOptionsForm) {
			filterOptionsForm.requestSubmit();
		}
	}

	selectFilterOption(event) {
		const { filterType, filterValue } = event.params;
		const urlParams = new URLSearchParams(window.location.search);

		if (urlParams.has(filterType)) {
			const inputField = $(this.filterFormTarget).find(`input[name="${filterType}"]`);
			const paramValues = new Set(urlParams.get(filterType).split(',')).add(filterValue);
			const paramString = [...paramValues].join(',');
			inputField.val(paramString);
		} else {
			this.valueFieldTarget.name = filterType;
			this.valueFieldTarget.value = filterValue;
		}

		this.closeFilterSections();
		this.filtersMenuTarget.style.display = 'none';
		this.filterFormTarget.requestSubmit();
	}

	submitMetadata() {
		const key = this.metadataKeyTarget.value;
		const value = this.metadataValueTarget.value;

		const urlParams = new URLSearchParams(window.location.search);
		const inputField = $(this.filterFormTarget).find(`input[name="metadata[${key}]"]`);

		if (urlParams.get(`metadata[${key}]`)) {
			const paramValues = new Set(urlParams.get(`metadata[${key}]`).split(',')).add(value);
			const paramString = [...paramValues].join(',');
			inputField.val(paramString);
		} else {
			this.valueFieldTarget.name = `metadata[${key}]`;
			this.valueFieldTarget.value = value;
		}

		this.closeFilterSections();
		this.filtersMenuTarget.style.display = 'none';
		this.filterFormTarget.requestSubmit();
	}

	clearFilterValues() {
		const queryFields = document.querySelectorAll('.page-filter-search-input');
		queryFields.forEach(inputField => (inputField.value = ''));
		this.valueFieldTarget.value = '';
		this.valueFieldTarget.name = '';
	}

	closeFilterSections() {
		// Step 1: Select the top-level 'ul' with the 'active' class
		var topLevelUl = document.querySelector('.dropdown-content ul.active');

		if (topLevelUl) {
			// Step 2: Remove 'active' class from the top-level 'ul'
			topLevelUl.classList.remove('active');

			// Step 3: Find and remove 'active' class from the child 'li' with 'active' within the top-level 'ul'
			var activeLi = topLevelUl.querySelector('li.active');
			if (activeLi) {
				activeLi.classList.remove('active');
			}
		}
	}

	getMetadataValues() {
		if (this.metadataKeyTarget.value) {
			this.metadataValuesFormTarget.requestSubmit();
		} else {
			this.metadataValueSectionTarget.remove();
			this.metadataSubmitButtonTarget.remove();
		}
	}
}

// // TODO: maybe remove, not sure what this is doing as the class `filters-active` doesn't
// // seem to be used anywhere
// function updateFilterControlsActiveState() {
// 	var container = document.getElementById('filter-controls');
// 	var activeFilters = container.querySelectorAll('.active-filter-item');

// 	// If there are active filters, add the 'active' class; otherwise, remove it
// 	if (activeFilters.length > 0) {
// 		container.classList.add('filters-active');
// 	} else {
// 		container.classList.remove('filters-active');
// 	}
// }

//SCROLL ME THE WAY
function scrollMeTheWay(selector, buffer = 20, speed = 2) {
	const elements = document.querySelectorAll(selector);

	elements.forEach(element => {
		let isHovering = false;
		let currentIndent = 0;
		let animationFrameId = null;

		const startAnimation = () => {
			const contentWidth = element.scrollWidth;
			const containerWidth = element.offsetWidth;
			const maxIndent = contentWidth - containerWidth + buffer;

			const animate = () => {
				if (isHovering && currentIndent < maxIndent) {
					currentIndent += speed;
					element.style.textIndent = `-${currentIndent}px`;
					animationFrameId = requestAnimationFrame(animate);
				}
			};

			animate();
		};

		const stopAnimation = () => {
			isHovering = false;
			cancelAnimationFrame(animationFrameId);
			currentIndent = 0;
			element.style.textIndent = `0px`;
		};

		element.addEventListener('mouseenter', () => {
			isHovering = true;
			startAnimation();
		});

		element.addEventListener('mouseleave', stopAnimation);
	});
}
