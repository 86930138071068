import Chart from 'chart.js/auto';

export function datasetBase(ctx, toMerge = {}) {
	// gradient values
	const gradient = ctx.createLinearGradient(0, 0, 0, 280);
	gradient.addColorStop(0, 'rgba(188, 221, 250, 1)'); // light blue gradient start
	gradient.addColorStop(1, 'rgba(188, 221, 250, 0)'); // light blue gradient fade out

	const base = {
		tension: 0,
		borderColor: '#4aa7f8',
		borderWidth: 3,
		backgroundColor: gradient,
		fill: 'start',
		pointBackgroundColor: 'transparent', // hide points unless hovered
		pointBorderColor: 'transparent', // hide points unless hovered
		pointBorderWidth: 2,
		pointRadius: 5,
		pointHoverBackgroundColor: '#4aa7f8',
		pointHoverBorderColor: '#fff',
		pointHoverBorderWidth: 2,
		pointHoverRadius: 5
	};

	return Object.assign({}, base, toMerge);
}

export function fontBase(toMerge = {}) {
	const base = {
		family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
		size: 13,
		weight: 500
	};
	return Object.assign({}, base, toMerge);
}

export function largeLineChart(
	ctx,
	gradientStart,
	gradientEnd,
	borderColor,
	pointColor,
	tension,
	label,
	labels,
	data,
	stepSize,
	tickCallback
) {
	const gradient = ctx.createLinearGradient(0, 0, 0, 280);
	gradient.addColorStop(0, gradientStart);
	gradient.addColorStop(1, gradientEnd);

	// Define the custom plugin
	const verticalLinePlugin = {
		id: 'verticalLine',
		afterEvent(chart, args) {
			const event = args.event;
			if (event.type === 'mousemove') {
				// Get the nearest points to the mouse cursor without intersect requirement
				const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: false }, true);
				if (points.length) {
					const firstPoint = points[0];
					chart._verticalLine = {
						x: firstPoint.element.x,
						y: chart.chartArea.bottom
					};
					// Set the active elements to trigger the hover effect on the nearest point
					chart.setActiveElements([
						{
							datasetIndex: firstPoint.datasetIndex,
							index: firstPoint.index
						}
					]);
				} else {
					chart._verticalLine = null;
					// Clear any active elements if no point is nearest
					chart.setActiveElements([]);
				}
			} else {
				chart._verticalLine = null;
				// Clear any active elements when not a mousemove event
				chart.setActiveElements([]);
			}
			chart.draw();
		},
		afterDraw(chart) {
			if (chart._verticalLine) {
				const ctx = chart.ctx;
				ctx.save();
				ctx.beginPath();
				ctx.strokeStyle = '#272727';
				ctx.moveTo(chart._verticalLine.x, chart.chartArea.top);
				ctx.lineTo(chart._verticalLine.x, chart._verticalLine.y);
				ctx.stroke();
				ctx.restore();
			}
		}
	};

	const chart = new Chart(ctx, {
		type: 'line',
		data: {
			labels: labels,
			datasets: [
				{
					label: label,
					tension: tension,
					data: data,
					borderColor: borderColor,
					borderWidth: 3,
					backgroundColor: gradient,
					fill: 'start',
					pointBackgroundColor: 'transparent', // hide points unless hovered
					pointBorderColor: 'transparent', // hide points unless hovered
					pointBorderWidth: 2,
					pointRadius: 5,
					pointHoverBackgroundColor: pointColor,
					pointHoverBorderColor: '#fff',
					pointHoverBorderWidth: 2,
					pointHoverRadius: 5
				}
			]
		},
		options: {
			animation: false,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false
				},
				tooltip: {
					intersect: false,
					mode: 'index',
					padding: '10',
					titleColor: '#babcc3',
					titleFont: {
						family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
						size: 13,
						weight: 500
					},
					bodyColor: '#fff',
					bodyFont: {
						family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
						size: 13,
						weight: 500
					},
					backgroundColor: '#272727',
					caretSize: 5,
					caretPadding: 5,
					cornerRadius: 5,
					displayColors: false
				}
			},
			scales: {
				y: {
					beginAtZero: true,
					grid: {
						drawTicks: false
					},
					border: {
						display: false,
						dash: [5, 5]
					},
					ticks: {
						padding: 20,
						color: '#babcc3',
						font: {
							family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
							size: 13,
							weight: 600
						},
						stepSize: stepSize,
						callback: tickCallback
					}
				},
				x: {
					grid: {
						display: false
					},
					border: {
						display: false
					},
					ticks: {
						padding: 20,
						color: '#babcc3',
						font: {
							family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
							size: 13,
							weight: 600
						},
						autoSkip: true,
						maxTicksLimit: 7,
						maxRotation: 0,
						minRotation: 0,
						// TODO: consider moment.js for handling dates
						callback: function (value, index) {
							const label = this.chart.data.labels[index];
							const dateStr = label && typeof label === 'string' ? label.split(',')[0] : value;
							return dateStr;
						}
					}
				}
			}
		},
		plugins: [verticalLinePlugin] // Register the plugin
	});
	return chart;
}

export function smallLineChart(canvasElement, chartLabel, chartLabels, chartData, chartSteps) {
	function createGradient(height) {
		const gradient = ctx.createLinearGradient(0, 0, 0, height);
		gradient.addColorStop(0, 'rgba(188, 221, 250, 1)'); // light blue gradient start
		gradient.addColorStop(1, 'rgba(188, 221, 250, 0)'); // light blue gradient fade out
		return gradient;
	}

	const ctx = canvasElement.getContext('2d');

	new Chart(ctx, {
		type: 'line',
		data: {
			labels: chartLabels,
			datasets: [
				{
					label: chartLabel,
					data: chartData,
					borderColor: '#4aa7f8', // blue
					borderWidth: 2,
					backgroundColor: createGradient(canvasElement.height),
					fill: 'start',
					pointRadius: 0,
					pointHoverRadius: 0
				}
			]
		},
		options: {
			animation: false,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false
				},
				tooltip: {
					enabled: false
				}
			},
			scales: {
				y: {
					display: false,
					ticks: {
						stepSize: chartSteps
					}
				},
				x: {
					display: false
				}
			},
			onResize: function (chart, size) {
				// update the gradient based on the chart's new size
				chart.data.datasets[0].backgroundColor = createGradient(size.height);
				chart.update();
			}
		}
	});
}
