import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="dropdowns"
export default class extends Controller {
	open(e) {
		e.stopPropagation();
		$('div.dropdown-menu:visible').hide();
		$(this.element).find('div.dropdown-menu').fadeIn(100);
	}

	keepOpen(e) {
		e.stopPropagation();
	}

	slideOpen(e) {
		e.preventDefault();

		const slideToggle = e.target.closest('[data-slide-content]');
		const contentId = slideToggle.dataset.slideContent;
		const contentShow = $('#' + contentId);
		const contentHide = $(slideToggle).parents('.dropdown-content');

		contentShow.animate(
			{
				width: 'toggle'
			},
			200
		);

		contentHide.toggleClass('dropdown-slide-out').animate(
			{
				width: 'toggle'
			},
			200
		);
	}
}
