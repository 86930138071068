import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="liveVisitors"
export default class extends Controller {
	connect() {
		this.interval = setInterval(() => {
			// re-trigger the request to get an updated live visitors count
			this.element.src = this.element.src;
		}, 10000);
	}
	disconnect() {
		clearInterval(this.interval);
	}
}
