import { Controller } from '@hotwired/stimulus';
import { largeLineChart } from '../helpers/charts';

// Connects to data-controller="webvitals"
export default class extends Controller {
	static targets = [
		'fcpChart',
		'lcpChart',
		'clsChart',
		'inpChart',
		'fcpCountryMap',
		'lcpCountryMap',
		'clsCountryMap',
		'inpCountryMap'
	];

	connect() {
		this.chartInstances = {};
		this.initializeCharts();
		this.initializeVitalsMap();
		this.generateStatusColumns();
		document.addEventListener('turbo:frame-load', event => {
			if (event.target.id === 'quantiled') {
				this.initializeCharts();
				this.initializeVitalsMap();
				this.generateStatusColumns();
			}
		});
	}

	initializeCharts() {
		const vitalCharts = [
			this.hasFcpChartTarget && this.fcpChartTarget,
			this.hasLcpChartTarget && this.lcpChartTarget,
			this.hasClsChartTarget && this.clsChartTarget,
			this.hasInpChartTarget && this.inpChartTarget
		].filter(Boolean);

		vitalCharts.forEach(vitalChart => {
			const chartDiv = $(vitalChart)[0];

			if (chartDiv) {
				const ctx = chartDiv.getContext('2d');
				const status = chartDiv.dataset.status;
				if (this.chartInstances[chartDiv.dataset.vital]) {
					this.chartInstances[chartDiv.dataset.vital].destroy();
				}
				const statusStyles = {
					good: { color: '#83bf7a', gradientEnd: 'rgba(131, 191, 122, 0)' },
					needs_work: { color: '#cec982', gradientEnd: 'rgba(206, 201, 130, 0)' },
					poor: { color: '#f27c98', gradientEnd: 'rgba(242, 124, 152, 0)' }
				};

				const chart = largeLineChart(
					ctx,
					statusStyles[status]['color'], // gradient start
					statusStyles[status]['gradientEnd'], // gradient end
					statusStyles[status]['color'], // line border color
					statusStyles[status]['color'], // line point color
					0, // line tension
					chartDiv.dataset.vital, // legend label
					JSON.parse(chartDiv.dataset.labels), // labels
					JSON.parse(chartDiv.dataset.values), // data
					100 // step size
				);
				this.chartInstances[chartDiv.dataset.vital] = chart;
				chart;
			}
		});
	}

	selectChartHeader(event) {
		const { path } = event.params;
		window.location.href = path;
	}

	initializeVitalsMap() {
		const vitalMaps = [
			this.hasFcpCountryMapTarget && this.fcpCountryMapTarget,
			this.hasLcpCountryMapTarget && this.lcpCountryMapTarget,
			this.hasClsCountryMapTarget && this.clsCountryMapTarget,
			this.hasInpCountryMapTarget && this.inpCountryMapTarget
		].filter(Boolean);

		vitalMaps.forEach(vitalMap => {
			const webVitalsCountryData = JSON.parse(vitalMap.dataset.countrydata);
			const vital = vitalMap.dataset.vital;
			const tag = `#${vital}WorldMap`;
			if ($(tag).length > 0) {
				$(tag).vectorMap({
					map: 'world_mill',
					backgroundColor: 'transparent',
					zoomOnScroll: false,
					regionStyle: {
						initial: {
							fill: '#f0f0f0',
							'fill-opacity': 1,
							stroke: 'none',
							'stroke-width': 1,
							'stroke-opacity': 1
						},
						hover: {
							fill: '#000000'
						}
					},
					series: {
						regions: [
							{
								values: this.getCountryColors(vital, webVitalsCountryData),
								attribute: 'fill'
							}
						]
					},
					onRegionTipShow: function (e, el, code) {
						if (webVitalsCountryData[code]) {
							el.html(`
							<span class="map-tip-country">${el.html()}</span>Score: ${webVitalsCountryData[code][vital]}
						`);
						}
					}
				});
			}
		});
	}

	// Set country color via score
	getCountryColors(vital, webVitalsCountryData) {
		const countryColors = {};
		const colorScales = {
			good: ['#a8e6a3', '#92d892', '#83bf7a'], // Light to dark green
			needs_work: ['#e5e5a3', '#d1d18c', '#c4be68'], // Light to dark yellow
			poor: ['#f9c1c8', '#f5a6b4', '#f27c98'] // Light to dark red
		};

		for (const code in webVitalsCountryData) {
			if (webVitalsCountryData.hasOwnProperty(code)) {
				const status = webVitalsCountryData[code][vital + '_status'];
				const score = webVitalsCountryData[code][vital];
				const colors = colorScales[status];
				if (!colors) {
					countryColors[code] = '#f7f7f7'; // Default color for undefined status
					continue;
				}

				// TODO: Assume score range 0-300 for demo
				let colorIndex;
				if (score <= 100) {
					colorIndex = 0; // Lightest shade for lower third of score range
				} else if (score <= 200) {
					colorIndex = 1; // Medium shade for middle third of score range
				} else {
					colorIndex = 2; // Darkest shade for upper third of score range
				}
				countryColors[code] = colors[colorIndex];
			}
		}
		return countryColors;
	}

	// Toggle full screen map
	toggleFullScreenMap(e) {
		const worldMap = e.target.closest('.worldMap');
		const mapSize = worldMap.dataset.mapSize;
		const mapElement = $(worldMap);
		const mapObject = mapElement.vectorMap('get', 'mapObject');

		if (mapSize === 'small' || !mapSize) {
			worldMap.setAttribute('data-map-size', 'full');
			$('body').css('overflow', 'hidden');
		} else if (mapSize === 'full') {
			worldMap.setAttribute('data-map-size', 'small');
			$('body').css('overflow', 'auto');
		}
		mapObject.updateSize();
	}

	// Highlight map regions
	highlightMapRegion(countryCode, vital) {
		const mapObject = $(`#${vital}WorldMap`).vectorMap('get', 'mapObject');
		mapObject.series.regions[0].setValues({ [countryCode]: '#000000' });
	}

	// Reset map regsions
	resetMapRegion(countryCode, vital, webVitalsCountryData) {
		const mapObject = $(`#${vital}WorldMap`).vectorMap('get', 'mapObject');
		const originalColors = this.getCountryColors(vital, webVitalsCountryData);
		mapObject.series.regions[0].setValues({ [countryCode]: originalColors[countryCode] });
	}

	// Country list columns
	generateStatusColumns() {
		const vitalMaps = [
			this.hasFcpCountryMapTarget && this.fcpCountryMapTarget,
			this.hasLcpCountryMapTarget && this.lcpCountryMapTarget,
			this.hasClsCountryMapTarget && this.clsCountryMapTarget,
			this.hasInpCountryMapTarget && this.inpCountryMapTarget
		].filter(Boolean);

		// Function to wrap each column in a dl.stats-list
		function wrapColumnInDL(column, vital) {
			let dlElement = document.createElement('dl');
			dlElement.classList.add('stats-list');

			column.querySelectorAll('dd').forEach(dd => {
				dlElement.appendChild(dd);
			});

			column.innerHTML = '';
			column.appendChild(dlElement);
		}

		function addHeaders(column, vital) {
			const dlElement = column.querySelector('dl.stats-list');
			let headerElement = document.createElement('dt');
			headerElement.innerHTML = `<span>COUNTRY</span><span>${vital.toUpperCase()}</span>`;
			dlElement.prepend(headerElement);
		}

		function possiblyAddNoCountriesMessage(column) {
			const dlElement = column.querySelector('dl.stats-list');
			// Check if there are no matching countries
			if (dlElement.querySelectorAll('dd').length === 0) {
				const ddElement = document.createElement('dd');
				const spanElement = document.createElement('span');
				spanElement.textContent = 'No matching countries';
				ddElement.appendChild(spanElement);
				dlElement.appendChild(ddElement);
			}
		}

		vitalMaps.forEach(vitalMap => {
			const webVitalsCountryData = JSON.parse(vitalMap.dataset.countrydata);
			const vital = vitalMap.dataset.vital;
			if (document.getElementById(`${vital}-country-status-columns`)) {
				document.getElementById(`${vital}-country-status-columns`).remove();
			}

			let container = document.createElement('div');
			container.id = `${vital}-country-status-columns`;
			container.className = 'widgets-col-3 ';

			document.getElementById(`${vital}-world-map-container`).appendChild(container);

			// Create columns for each status
			let poorColumn = document.createElement('div');
			let needsWorkColumn = document.createElement('div');
			let goodColumn = document.createElement('div');

			[poorColumn, needsWorkColumn, goodColumn].forEach(col => {
				col.classList.add('widget');
			});

			// Convert object to array and sort by score descending
			const sortedCountries = Object.entries(webVitalsCountryData)
				.map(entry => ({ code: entry[0], ...entry[1] }))
				.sort((a, b) => parseFloat(b[vital]) - parseFloat(a[vital]));

			// Append countries to the appropriate column based on status
			sortedCountries.forEach(country => {
				let countryElement = document.createElement('dd');
				const className = country.label ? `fi fi-${country.code.toLowerCase()}` : '';
				countryElement.innerHTML = `<span><div><span class="${className}"></span> ${
					country.label || 'Unknown'
				}</div></span><span>${country[vital]}</span>`;

				// Mouse enter event
				countryElement.addEventListener('mouseenter', () => {
					this.highlightMapRegion(country.code, vital);
				});

				// Mouse leave event
				countryElement.addEventListener('mouseleave', () => {
					this.resetMapRegion(country.code, vital, webVitalsCountryData);
				});
				switch (country[vital + '_status']) {
					case 'poor':
						poorColumn.appendChild(countryElement);
						break;
					case 'needs_work':
						needsWorkColumn.appendChild(countryElement);
						break;
					case 'good':
						goodColumn.appendChild(countryElement);
						break;
				}
			});

			wrapColumnInDL(poorColumn, vital);
			wrapColumnInDL(needsWorkColumn, vital);
			wrapColumnInDL(goodColumn, vital);

			[poorColumn, needsWorkColumn, goodColumn].forEach(col => {
				addHeaders(col, vital);
				possiblyAddNoCountriesMessage(col);
			});
			// Create headers for each column and prepend them to the respective .stats-list
			const poorHeader = document.createElement('header');
			poorHeader.className = 'widget-header';
			const poorHeaderH5 = document.createElement('h5');
			poorHeaderH5.className = 'color-red';
			poorHeaderH5.innerHTML =
				'<svg width="20" height="20" viewBox="0 -960 960 960"><path d="M330-120 120-330v-300l210-210h300l210 210v300L630-120H330Zm36-190 114-114 114 114 56-56-114-114 114-114-56-56-114 114-114-114-56 56 114 114-114 114 56 56Zm-2 110h232l164-164v-232L596-760H364L200-596v232l164 164Zm116-280Z"></path></svg> Poor';
			poorHeader.appendChild(poorHeaderH5);
			poorColumn.prepend(poorHeader);

			const needsWorkHeader = document.createElement('header');
			needsWorkHeader.className = 'widget-header';
			const needsWorkHeaderH5 = document.createElement('h5');
			needsWorkHeaderH5.className = 'color-yellow-dark';
			needsWorkHeaderH5.innerHTML =
				'<svg width="20" height="20" viewBox="0 -960 960 960"><path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z"></path></svg> Needs Work';
			needsWorkHeader.appendChild(needsWorkHeaderH5);
			needsWorkColumn.prepend(needsWorkHeader);

			const goodHeader = document.createElement('header');
			goodHeader.className = 'widget-header';
			const goodHeaderH5 = document.createElement('h5');
			goodHeaderH5.className = 'color-green-dark';
			goodHeaderH5.innerHTML =
				'<svg width="20" height="20" viewBox="0 -960 960 960"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg> Good';
			goodHeader.appendChild(goodHeaderH5);
			goodColumn.prepend(goodHeader);

			// Append columns to the container
			[poorColumn, needsWorkColumn, goodColumn].forEach(col => {
				container.appendChild(col);
			});
		});
	}
}
