import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';
import { calculatePercent, prettyPercent } from '../helpers/numbers';

export default class extends Controller {
	connect() {
		this.initializePieChart();
	}

	initializePieChart() {
		const canvas = this.element;
		const ctx = canvas.getContext('2d');
		const labels = JSON.parse(canvas.dataset.labels);
		const data = JSON.parse(canvas.dataset.values);
		const title = canvas.dataset.title;
		const total = parseInt(canvas.dataset.total);

		if (canvas.checkVisibility()) {
			if (this.pieChart) {
				this.pieChart.destroy();
			}

			this.pieChart = new Chart(ctx, {
				type: 'pie',
				data: {
					labels: labels,
					datasets: [
						{
							data: data,
							backgroundColor: [
								'#4aa7f8',
								'#55acf8',
								'#60b2f9',
								'#6bb7f9',
								'#77bdf9',
								'#82c2fa',
								'#8ec8fa',
								'#99cdfa',
								'#a4d3fb',
								'#b0d9fb',
								'#bbdefc',
								'#c7e4fc',
								'#d2e9fc',
								'#ddeffd',
								'#e8f4fd'
							]
						}
					]
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							position: 'right',
							labels: {
								boxWidth: 10,
								boxHeight: 10,
								usePointStyle: 'circle',
								color: '#272727',
								font: {
									family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
									size: 14,
									weight: 400
								},
								padding: 14
							}
						},
						tooltip: {
							intersect: true,
							mode: 'index',
							padding: '10',
							titleColor: '#babcc3',
							titleFont: {
								family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
								size: 13,
								weight: 500
							},
							bodyColor: '#fff',
							bodyFont: {
								family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
								size: 13,
								weight: 500
							},
							backgroundColor: '#272727',
							caretSize: 5,
							caretPadding: 5,
							cornerRadius: 5,
							displayColors: false,
							callbacks: {
								label: data => {
									const percent = calculatePercent(data.raw, total);
									return `${data.formattedValue}${total ? ` (${prettyPercent(percent)})` : ''}`;
								}
							}
						}
					},
					title: {
						display: false,
						text: title
					},
					animation: {
						animateScale: true,
						animateRotate: true
					}
				}
			});
		}
	}
}
