import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="searachables"
export default class extends Controller {
	connect() {
		this.initializeSearchFilters();
	}

	initializeSearchFilters() {
		const searchableElements = document.querySelectorAll('.searchable');
		searchableElements.forEach(element => {
			let searchInputWrapper = element.querySelector('.searchInputWrapper');
			if (!searchInputWrapper) {
				return;
			}
			let searchInput = searchInputWrapper.querySelector('input');

			if (!searchInput) {
				searchInput = document.createElement('input');
				searchInput.setAttribute('type', 'text');
				searchInput.setAttribute('placeholder', 'Search here...');
				searchInputWrapper.appendChild(searchInput);
			}
			const statsList = element.querySelector('.stats-list');
			const statsItems = statsList.querySelectorAll('dd');

			let emptyState = document.createElement('dd');
			emptyState.textContent = 'No matching results';
			emptyState.style.display = 'none';
			statsList.appendChild(emptyState);

			searchInput.addEventListener('input', () => {
				const searchTerm = searchInput.value.toLowerCase();
				let hasResults = false;
				statsItems.forEach(item => {
					const text = item.textContent.toLowerCase();
					if (text.includes(searchTerm)) {
						this.show(item);
						hasResults = true;
					} else {
						this.hide(item);
						item.style.display = 'none';
					}
				});
				if (hasResults) {
					this.hide(emptyState);
				} else {
					this.show(emptyState);
				}
			});

			searchInput.addEventListener('input', () => {
				if (!searchInput.value) {
					statsItems.forEach(item => {
						this.show(item);
					});
					this.hide(emptyState);
				}
			});
		});
	}

	show(item) {
		item.style.display = '';
	}

	hide(item) {
		item.style.display = 'none';
	}
}
