import { Controller } from '@hotwired/stimulus';
import { largeLineChart } from '../helpers/charts';

// Connects to data-controller="linkStatsDisplays"
export default class extends Controller {
	connect() {
		this.loadItUp();
	}

	loadItUp() {
		if (this.element) {
			const canvas = this.element;
			const ctx = canvas.getContext('2d');
			largeLineChart(
				ctx,
				'#83bf7a',
				'rgba(131, 191, 122, 0)',
				'#83bf7a',
				'#83bf7a',
				0, // line tension
				'Clicks',
				JSON.parse(canvas.dataset.labels), // labels
				JSON.parse(canvas.dataset.values), // data
				100 // step size
			);
		}
	}
}
