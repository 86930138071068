import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';

// Connects to data-controller="botActivity"
export default class extends Controller {
	connect() {
		this.initializeDoughnutChart();
	}

	initializeDoughnutChart() {
		const canvas = this.element;
		const ctx = canvas.getContext('2d');
		const data = JSON.parse(canvas.dataset.values);
		const botsTotal = data.reduce((acc, val) => acc + val, 0);

		if (this.doughnutChart) {
			this.doughnutChart.destroy();
		}

		this.doughnutChart = new Chart(ctx, {
			type: 'doughnut',
			data: {
				labels: ['Likely Human', 'Bots'],
				datasets: [
					{
						label: 'Bot Activity',
						data: data,
						backgroundColor: [
							'#63A5F2', // blue dark
							'#B8D8F8' // blue light
						],
						borderWidth: 0,
						borderRadius: 5, // Creates rounded edges
						circumference: 270, // Custom circumference
						rotation: 226, // Starts rotation angle
						spacing: 5, // Space between segments
						hoverOffset: 0
					}
				]
			},
			options: {
				maintainAspectRatio: false,
				cutout: '80%', // Controls segment thickness
				plugins: {
					legend: {
						position: 'bottom',
						labels: {
							usePointStyle: true,
							boxWidth: 8,
							boxHeight: 8,
							padding: 20,
							color: '#272727',
							font: {
								family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
								size: 14,
								weight: 500
							}
						}
					},
					tooltip: {
						intersect: true,
						mode: 'index',
						padding: '10',
						titleColor: '#babcc3',
						titleFont: {
							family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
							size: 13,
							weight: 500
						},
						bodyColor: '#fff',
						bodyFont: {
							family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
							size: 13,
							weight: 500
						},
						backgroundColor: '#272727',
						caretSize: 5,
						caretPadding: 5,
						cornerRadius: 5,
						xAlign: 'center',
						yAlign: 'center',
						displayColors: false,
						callbacks: {
							label: function (context) {
								const dataPoint = context.raw;
								const percentage = ((dataPoint / botsTotal) * 100).toFixed(1) + '%';
								return percentage;
							}
						}
					}
				}
			}
		});
	}
}
