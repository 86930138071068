import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="settings"
export default class extends Controller {
	static targets = ['settingsForm'];

	saveSettings() {
		this.settingsFormTarget.requestSubmit();
	}
}
