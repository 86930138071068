import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	copy(event) {
		event.preventDefault();

		const codeToCopy = this.element.getAttribute('data-code-copy');
		const textArea = document.createElement('textarea');
		textArea.value = codeToCopy;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand('copy');
		document.body.removeChild(textArea);

		// SVG to swap after copying
		const newSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 448 512"><path d="M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z"/></svg>`;

		// replace current SVG with new SVG
		this.element.innerHTML = newSvg;

		// restore original SVG after 3 seconds
		setTimeout(() => {
			const originalSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 448 512"><path d="M416 352H192V32H338.7L416 109.3V352zM448 96L352 0H192 160V32 352v32h32H416h32V352 96zM32 128H0v32V480v32H32 256h32V480 416H256v64H32V160h96V128H32z"></path></svg>`;
			this.element.innerHTML = originalSvg;
		}, 3000);
	}
}
