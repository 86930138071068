import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="tabs"
export default class extends Controller {
	static targets = ['dropdownTitle'];

	selectTab(event) {
		const tab = $(event.currentTarget);
		tab.siblings().removeClass('active');
		tab.addClass('active');
	}

	updateDropdownTitle(event) {
		this.dropdownTitleTarget.innerText = event.target.text.trim();
	}
}
