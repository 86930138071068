import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	connect() {
		this.loadItUp();
	}

	loadItUp() {
		//  this allows us to redirect or render in the same action
		//  details here: https://ducktypelabs.com/turbo-break-out-and-redirect/
		https: Turbo.StreamActions.redirect = function () {
			Turbo.visit(this.getAttribute('target'));
		};
		this.linkCreationInit();
		this.linkEditingInit();

		// this prevents a double render of index lists
		// when we click on a pagination link
		document.addEventListener('click', event => {
			if (event.target.tagName === 'A') {
				Turbo.cache.clear();
			}
		});

		// this prevents a double render of the index lists
		// when the Add Link button is clicked
		document.addEventListener('click', event => {
			if (event.target.id === 'add-link-button') {
				console.log('CLEARING');
				Turbo.cache.clear();
			}
		});

		document.addEventListener('turbo:frame-load', event => {
			if (event.target.id === 'edit' || event.target.id === 'links' || event.target.id === 'new') {
				Turbo.cache.clear();
				this.linkCreationInit();
				this.linkEditingInit();
			}
		});
	}

	linkEditingInit() {
		// EDITING
		function toggleEditSection(editingSection) {
			editingSection.classList.toggle('hidden');
		}

		// open editing section when clicking on edit button
		document.querySelectorAll('.menu-btn-edit-link').forEach(button => {
			button.addEventListener('click', function () {
				const editingSection = button.closest('.link-container').querySelector('.edit-link-container');
				if (editingSection.classList.contains('hidden')) {
					toggleEditSection(editingSection);
				}
			});
		});

		const editingSections = document.querySelectorAll('.edit-link-container');

		// Close edit section when an element with .close inside edit section is clicked
		editingSections.forEach(editingSection => {
			editingSection.querySelectorAll('.close').forEach(function (closeButton) {
				closeButton.addEventListener('click', function () {
					if (!editingSection.classList.contains('hidden')) {
						toggleEditSection(editingSection);
					}
				});
			});
		});

		// Close edit section with ESC button
		editingSections.forEach(editingSection => {
			document.addEventListener('keydown', function (event) {
				if (event.key === 'Escape' || event.key === 'Esc') {
					if (editingSection && !editingSection.classList.contains('hidden')) {
						toggleEditSection(editingSection);
					}
				}
			});
		});
	}

	linkCreationInit() {
		// URL INPUTS VARS
		const settingsShortUrlInput = document.getElementById('short-url-input');
		const mainShortUrlInput = document.getElementById('new-short-url');
		const settingsDestinationUrlInput = document.getElementById('destination-url-input');
		const mainDestinationUrlInput = document.getElementById('new-full-url');

		// PREVENT SPACES IN LINK NAME
		mainShortUrlInput &&
			mainShortUrlInput.addEventListener('input', function () {
				this.value = this.value.replace(/\s+/g, '-');
			});
		settingsShortUrlInput &&
			settingsShortUrlInput.addEventListener('input', function () {
				this.value = this.value.replace(/\s+/g, '-');
			});

		// SYNC LINK INPUTS
		mainShortUrlInput &&
			mainShortUrlInput.addEventListener('input', function () {
				settingsShortUrlInput.value = this.value;
			});
		settingsShortUrlInput &&
			settingsShortUrlInput.addEventListener('input', function () {
				mainShortUrlInput.value = this.value;
			});

		mainDestinationUrlInput &&
			mainDestinationUrlInput.addEventListener('input', function () {
				settingsDestinationUrlInput.value = this.value;
			});
		settingsDestinationUrlInput &&
			settingsDestinationUrlInput.addEventListener('input', function () {
				mainDestinationUrlInput.value = this.value;
			});

		//ANIMATE LIST ITEMS
		const linkItems = document.querySelectorAll('.links-container .link-item');
		linkItems.forEach((item, index) => {
			item.style.animationDelay = `${index * 0.1}s`;
		});
	}
}
