import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';

export default class extends Controller {
	connect() {
		this.initializeChart();
		this.initializeActivityLog();
	}

	initializeChart() {
		const canvas = this.element.querySelector('#chart-event-actions canvas');
		const ctx = canvas.getContext('2d');

		const gradient = ctx.createLinearGradient(0, 0, 0, 280);
		gradient.addColorStop(0, '#8dc5f6'); // blue
		gradient.addColorStop(1, '#c8daff'); // light blue

		// calculate the last 30 days
		const today = new Date();
		const last30Days = Array.from({ length: 30 }, (_, i) => {
			const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
			return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
		}).reverse();

		const eventsChart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: last30Days,
				datasets: [
					{
						label: 'Actions',
						// example data for each of the last 30 days (randomly generated demo content)
						data: new Array(30).fill(null).map(() => Math.floor(Math.random() * 1000)),
						backgroundColor: gradient
					}
				]
			},
			options: {
				animation: false,
				maintainAspectRatio: false,
				scales: {
					y: {
						beginAtZero: true,
						grid: {
							drawTicks: false
						},
						border: {
							display: false,
							dash: [5, 5]
						},
						ticks: {
							padding: 20,
							color: '#babcc3',
							font: {
								family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
								size: 13,
								weight: 600
							},
							stepSize: 500,
							callback: function (value) {
								// tick callback
								if (value >= 1000 && value < 1000000) {
									return value / 1000 + 'K'; // Convert to K for thousands
								} else if (value >= 1000000) {
									return value / 1000000 + 'M'; // Convert to M for millions
								} else {
									return value; // Return the original value for smaller numbers
								}
							}
						}
					},
					x: {
						grid: {
							display: false
						},
						border: {
							display: false
						},
						ticks: {
							display: false, // remove this if we do want to show the x-axis labels
							padding: 20,
							color: '#babcc3',
							font: {
								family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
								size: 13,
								weight: 600
							},
							autoSkip: true,
							maxTicksLimit: 7,
							maxRotation: 0,
							minRotation: 0,
							// TODO: consider moment.js for handling dates
							callback: function (value, index) {
								const label = this.chart.data.labels[index];
								const dateStr = label && typeof label === 'string' ? label.split(',')[0] : value;
								return dateStr;
							}
						}
					}
				},
				plugins: {
					legend: { display: false },
					tooltip: {
						intersect: false,
						mode: 'index',
						padding: '10',
						titleColor: '#babcc3',
						titleFont: {
							family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
							size: 13,
							weight: 500
						},
						bodyColor: '#fff',
						bodyFont: {
							family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
							size: 13,
							weight: 500
						},
						backgroundColor: '#272727',
						caretSize: 5,
						caretPadding: 0,
						cornerRadius: 5,
						displayColors: false
					}
				}
			}
		});
	}

	initializeActivityLog() {
		$('.events-activity tr').click(function () {
			// close any open .event-log that is not the one being clicked on
			$('.events-log').not($(this).next('.events-log')).find('.row').slideUp(250);
			// now open the .event-log that was intended to slide down
			$(this).next('.events-log').find('.row').slideToggle(250);
			// toggle the chevron icon
			$(this)
				.find('.icon-chevron-down, .icon-chevron-up')
				.toggleClass('icon-chevron-down icon-chevron-up');
			// reset the chevron icon on any other rows to the default
			$('.events-activity tr')
				.not($(this))
				.find('td:last-child .icon-chevron-up')
				.removeClass('icon-chevron-up')
				.addClass('icon-chevron-down');
		});
	}
}
