import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';

// Connects to data-controller="popularDays"
export default class extends Controller {
	connect() {
		this.initializeBarChart();
	}

	initializeBarChart() {
		// const chartElement = document.getElementById('chart-popular-days').querySelector('canvas');
		const canvas = this.element;
		const ctx = canvas.getContext('2d');

		// Sample traffic data for each day of the week
		const data = JSON.parse(canvas.dataset.values);
		const labels = JSON.parse(canvas.dataset.labels);

		if (this.chart) {
			this.chart.destroy();
		}

		this.chart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: labels,
				datasets: [
					{
						label: 'Popular Days',
						data: data,
						backgroundColor: '#83bf7a',
						borderWidth: 0,
						borderRadius: 4,
						borderSkipped: false
					}
				]
			},
			options: {
				scales: {
					x: {
						grid: {
							display: false
						},
						ticks: {
							autoSkip: false,
							color: '#babcc3',
							font: {
								family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
								size: 13,
								weight: 600
							}
						},
						border: {
							display: false
						}
					},
					y: {
						display: false
					}
				},
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false
					},
					tooltip: {
						enabled: false
					}
				},
				hover: {
					mode: null // Disable hover effects
				}
			}
		});
	}
}
