import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	open(e) {
		const dialogOpen = e.target.closest('.dialog-open');
		const dialogTarget = dialogOpen.dataset.dialogTarget;
		const dialog = $('[data-dialog-content=' + dialogTarget + ']');

		// show the dialog
		dialog.fadeIn(300);
		dialog.focus();

		// if body has scrollbar, add padding to equal the width of the scrollbar
		var scrollbarWidth = window.innerWidth - document.body.clientWidth;
		// add body padding if a vertical scrollbar exists
		if (scrollbarWidth > 0) {
			$('html').css('padding-right', scrollbarWidth);
		}
		// hide body overflow
		$('body').addClass('no-scroll');
	}

	close(e) {
		const dialog = e.target.closest('.dialog');
		$(dialog).fadeOut(150, function () {
			// show body overflow again and remove padding
			$('html').css('padding-right', '0');
			$('body').removeClass('no-scroll');
		});
	}
}
