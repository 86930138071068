import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';

// Connects to data-controller="newUsers"
export default class extends Controller {
	connect() {
		this.initializePieChart();
	}

	initializePieChart() {
		const canvas = this.element;
		const ctx = canvas.getContext('2d');
		const data = JSON.parse(canvas.dataset.values);
		const newUsersTotal = data.reduce((acc, val) => acc + val, 0);

		if (this.pieChart) {
			this.pieChart.destroy();
		}

		this.pieChart = new Chart(ctx, {
			type: 'pie',
			data: {
				labels: ['New', 'Return'],
				datasets: [
					{
						label: 'New Users',
						data: data,
						backgroundColor: [
							'#9eb592', // green
							'#90C0F5' // blue
						],
						borderWidth: 0,
						offset: 3,
						hoverOffset: 3
					}
				]
			},
			options: {
				maintainAspectRatio: false,
				cutout: '0%',
				plugins: {
					legend: {
						position: 'bottom',
						labels: {
							usePointStyle: true,
							boxWidth: 8,
							boxHeight: 8,
							padding: 20,
							color: '#272727',
							font: {
								family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
								size: 14,
								weight: 500
							}
						}
					},
					tooltip: {
						intersect: true,
						mode: 'index',
						padding: '10',
						titleColor: '#babcc3',
						titleFont: {
							family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
							size: 13,
							weight: 500
						},
						bodyColor: '#fff',
						bodyFont: {
							family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
							size: 13,
							weight: 500
						},
						backgroundColor: '#272727',
						caretSize: 5,
						caretPadding: 5,
						cornerRadius: 5,
						xAlign: 'center',
						yAlign: 'center',
						displayColors: false,
						callbacks: {
							label: function (context) {
								const dataPoint = context.raw;
								const percentage = ((dataPoint / newUsersTotal) * 100).toFixed(1) + '%';
								return percentage;
							}
						}
					}
				}
			}
		});
	}
}
