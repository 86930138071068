import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	connect() {
		setTimeout(() => {
			const flashItems = document.querySelectorAll('.flash');
			flashItems.forEach(flashItem => {
				flashItem.remove();
			});
		}, 5000); // Remove flash message after 5 seconds
	}
}
