import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';

// Connects to data-controller="usersPerMinute"
export default class extends Controller {
	connect() {
		this.initializeBarChart();
	}

	initializeBarChart() {
		const canvas = this.element;
		const ctx = canvas.getContext('2d');
		const data = JSON.parse(canvas.dataset.values);
		const labels = JSON.parse(canvas.dataset.labels);

		if (this.barChart) {
			this.barChart.destroy();
		}

		this.barChart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: labels.map(minutesAgo =>
					minutesAgo === 0
						? 'Current Minute'
						: `${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`
				), // Assuming each label represents a minute interval
				datasets: [
					{
						label: 'Users',
						data: data,
						backgroundColor: [
							'#63A5F2' // blue
						],
						borderWidth: 0
					}
				]
			},
			options: {
				scales: {
					x: {
						grid: {
							display: false
						},
						border: {
							display: false
						},
						ticks: {
							display: false
						}
					},
					y: {
						beginAtZero: true,
						grid: {
							display: false
						},
						border: {
							display: false
						},
						ticks: {
							display: false
						}
					}
				},
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false
					},
					tooltip: {
						intersect: true,
						mode: 'index',
						padding: '10',
						titleColor: '#babcc3',
						titleFont: {
							family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
							size: 13,
							weight: 500
						},
						bodyColor: '#fff',
						bodyFont: {
							family: "'Mona Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
							size: 13,
							weight: 500
						},
						backgroundColor: '#272727',
						caretSize: 5,
						caretPadding: 5,
						cornerRadius: 5,
						displayColors: false
					}
				}
			}
		});
	}
}
