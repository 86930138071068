import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import moment from 'moment';

const dateFormat = 'yy-mm-dd';

// Connects to data-controller="datePicker"
export default class extends Controller {
	static targets = [
		'dropdownTitle',
		'dateRangePicker',
		'dateRangePickerForm',
		'startDate',
		'endDate',
		'applyButton'
	];

	connect() {
		this.initJQueryDatePicker();
	}

	initJQueryDatePicker() {
		const startDateField = $(this.startDateTarget);
		const endDateField = $(this.endDateTarget);
		const applyButton = this.applyButtonTarget;

		function validateDates(date, details) {
			try {
				const startDate = startDateField.val();
				const endDate = endDateField.val();

				$.datepicker.parseDate(dateFormat, startDate);
				$.datepicker.parseDate(dateFormat, endDate);

				if (startDate && endDate) {
					applyButton.disabled = false;
				}

				if (details.id === 'startDate') {
					// annoying but have to wait a tick otherwise the end date
					// datepicker flickers and disappears
					setTimeout(() => endDateField.select());
				}
			} catch {
				applyButton.disabled = true;
			}
		}

		startDateField.datepicker({
			showOn: 'focus',
			dateFormat,
			nextText: '>',
			prevText: '<',
			beforeShow: function (input, inst) {
				$('#chart-dates').append(inst.dpDiv);
			},
			onSelect: validateDates,
			onClose: function (selectedDate) {
				if (selectedDate) {
					const minDate = $.datepicker.parseDate(dateFormat, selectedDate);
					endDateField.datepicker('option', 'minDate', minDate);
				}
			}
		});

		endDateField.datepicker({
			showOn: 'focus',
			dateFormat,
			minDate: startDateField.val(),
			nextText: '>',
			prevText: '<',
			beforeShow: function (input, inst) {
				$('#chart-dates').append(inst.dpDiv);
			},
			onSelect: validateDates
		});
	}

	validateDate(event) {
		const { value } = event.target;

		try {
			if (!value) {
				this.applyButtonTarget.disabled = true;
			} else {
				$.datepicker.parseDate(dateFormat, value);
				this.applyButtonTarget.disabled = false;
			}
		} catch {
			this.applyButtonTarget.disabled = true;
		}
	}

	selectPreset(event) {
		event.preventDefault();

		const { starting, ending } = event.params;

		this.startDateTarget.value = starting;
		this.endDateTarget.value = ending;

		$(this.dropdownTitleTarget).html(event.target.innerText);
		$('.dropdown-menu.dropdown-right').fadeOut(100);

		this.dateRangePickerFormTarget.requestSubmit();
	}

	applyRange(event) {
		event.preventDefault();

		const startDateValue = $(this.startDateTarget).val();
		const endDateValue = $(this.endDateTarget).val();

		const prettyFormat = 'MMM D, YYYY';
		const startDateFormatted = moment(startDateValue).format(prettyFormat);
		const endDateFormatted = moment(endDateValue).format(prettyFormat);

		$(this.dropdownTitleTarget).html(
			startDateValue === endDateValue
				? `${startDateFormatted}`
				: `${startDateFormatted} to ${endDateFormatted}`
		);
		$('.dropdown-menu.dropdown-right').fadeOut(100);

		this.dateRangePickerFormTarget.requestSubmit();
	}

	backMenuDate(event) {
		event.preventDefault();
		$('.dropdown-slide-out').removeClass('dropdown-slide-out').css({ display: 'block' });
		$('#chart-dates').css({ display: 'none' });
	}
}
