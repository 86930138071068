// Hello!
const images = require.context('../images', true);
import './main.scss';
import Chart from 'chart.js/auto';

// toggle the mobile nav in regular pages and sidebar nav in dashboard pages
$(document).on('click.touchstart.mobileNav', '#navigation-header .mobile-nav-toggle', function (e) {
	e.preventDefault();
	$('#navigation-header').toggleClass('toggled'); // toggle for regular page navigation
	$('#sidebar').toggleClass('toggled'); // toggle for dashboard page sidebar navigation
});

$(document).on('turbolinks:load', function () {
	// reset sidebar nav slide on resize if browser width doesn't match the breakpoint where these are visible
	$(window)
		.off('resize.resetNav')
		.on('resize.resetNav', function () {
			if ($(window).width() > 1285) {
				$('#navigation-header').removeClass('toggled');
				$('#sidebar').removeClass('toggled');
			}
		});

	/* Progress bar */
	Turbo.setProgressBarDelay(100);

	document.addEventListener('turbo:before-fetch-request', function (e) {
		if (e.target.dataset.turboProgressBar === 'true') {
			Turbo.navigator.delegate.adapter.showProgressBar();
		}
	});

	document.addEventListener('turbo:frame-render', function (e) {
		Turbo.navigator.delegate.adapter.progressBar.hide();

		if (e.target.dataset.scrollToTop === 'true') {
			window.scrollTo(0, 0);
		}
	});

	// added this custom turbo stream action to dynamically
	// add a class to an html element outside of a partial
	Turbo.StreamActions.add_class = function () {
		this.targetElements.forEach(target => {
			target.classList.add(this.templateContent.textContent);
		});
	};

	/* Dropdowns */

	// hide dropdown if clicked anywhere outside
	$('html').click(function () {
		$('.dropdown-menu:visible').hide();
	});

	// allow content inside the dropdown to be clicked without hiding the menu
	$('#date-filter-toggle .dropdown-menu').click(function (event) {
		event.stopPropagation();
	});

	// tracking the Get Started form/button on the /accounts/new page
	const getStartedFormButton = document.getElementById('getStartedFormButton');
	if (getStartedFormButton) {
		getStartedFormButton.addEventListener('click', () => {
			window.fullres ||= { events: [] };
			window.fullres.events.push({ key: 'getStartedFormButton', formID: 'getStartedForm' });
		});
	}
});
